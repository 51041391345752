import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";
import ButtonLink from "../components/elements/ButtonLink";
import {
  BlockWithIcon,
  BlockWithIconIcon,
  BlockWithIconText,
  BlockWithIconWrap
} from "../components/elements/BlockWithIcon";
import PatientResponseToTherapy from "../components/images/PatientResponseToTherapy";

const Providers = () => (
  <Index>
    <Seo title="For Healthcare Providers" />
    <PageHeader>
      <h1>For Healthcare Providers</h1>
      <h2>Easy Access to the Data and Expertise You Need</h2>
      <p className="no-margins">
        <ButtonLink href="/request-demo" text="Schedule a Demo" type="primary" />
      </p>
    </PageHeader>
    <Container>
      <blockquote className="full-width">
        <p>
          "Patients are faced with complications resulting from a therapy they <br className="hide-medium" />
          don’t need. And, because the model will evolve to eventually <br className="hide-medium" />
          incorporate AI machine learning, the data will continue to support <br className="hide-medium" />
          increasingly improved outcomes."
        </p>
        <footer>
          <b>Todd Levine, MD</b> <br />
          Medical Director, Honor Health, Neurology
        </footer>
      </blockquote>
    </Container>
    <Container id="patient-response-to-therapy">
      <h2 className="align-center text-blue">Patient Response to Therapy</h2>
      <PatientResponseToTherapy />
    </Container>
    <Container id="improved-prescribing-process" extraInnerClasses="banner-with-bg">
      <h2>IMPROVED UTILIZATION REVIEW = MORE ACCURATE ACCESS TO CARE</h2>
      <p>
        InCircle’s population management tool delivers much-needed longitudinal <br/>
        patient data. Our emphasis on helping streamline the prescribing process <br />
        will help ensure the patients receive the right drug at the right time.
      </p>
    </Container>
    <Container>
      <BlockWithIconWrap layout="vertical">
        <BlockWithIcon>
          <BlockWithIconIcon iconName="glasses" />
          <BlockWithIconText>
            <h2>Knowledge + Data Minimizes <br />Waste and Lowers Costs</h2>
            <p>
              InCircle combines the knowledge of key opinion leaders with <br />
              the data needed to make the right prescribing decision. This model <br />
              can not only lower the rates of misdiagnosis and the overutilization <br />
              of IG, it can also be instrumental in minimizing waste and <br />
              lowering costs.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
        <BlockWithIcon>
          <BlockWithIconIcon iconName="eye-magnifier" />
          <BlockWithIconText>
            <h2>Transparency <br />in Decision Making</h2>
            <p>
              InCircle provides consistent and transparent decision making. Our <br />
              expert data extractors and algorithm can summarize the case to be <br />
              easily removed. Our program synthesizes the data so it makes sense <br />
              for diagnostic criteria and analyzes insurance data for <br />
              treatment responses.
            </p>
          </BlockWithIconText>
        </BlockWithIcon>
      </BlockWithIconWrap>
    </Container>
  </Index>
)

export default Providers
